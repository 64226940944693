<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestión de Accidentes e Incidentes</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name: 'Accidente e Incidentes'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardBody>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(varificarAccion)">
                                <b-row>
                                    <b-col md="12">
                                        <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                            <b-form-group label="Guia Principal:" class="mb-2">
                                                <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                                <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosAccionIncidentes.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosAccionIncidentes.idGuia" :options="listUsuarios" @search:blur="blurAccidenteIncidentes">
                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model.trim="datosAccionIncidentes.fecha"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="dia de viaje" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Día de Viaje:" class="mb-2">
                                                <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Dia de Viaje" v-model.trim="datosAccionIncidentes.diaViaje"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="12" class="my-2">
                                        <span class="h6 text-muted">Lista de Pasajeros Vinculados</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="modalRegPasajeroVinculados=true">
                                            <i class="fas fa-plus fa-sm"></i> Agregar
                                        </CButton>
                                    </b-col>
                                    <b-col md="12">
                                        <b-table :items="datosAccionIncidentes.listPasajerosVinculados" :fields="cabeceraPasajeroVinculados" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                            <template v-slot:cell(nombres)="data">
                                                {{data.item.nombre}}
                                            </template>
                                            <template v-slot:cell(apellido)="data">
                                                {{data.item.apellido}}
                                            </template>
                                            <template v-slot:cell(nrodoc)="data">
                                                {{data.item.nrodoc}}
                                            </template>
                                            <template v-slot:cell(opciones)="param">
                                                <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActPasajerosVinculados(param)" variant="dark" class=" mr-1 mb-1">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                    </span>
                                                </b-button>

                                                <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarPasajeroVinculados(param)" variant="danger">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </span>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>

                                    <b-col md="12" class="my-2">
                                        <span class="h6 text-muted">Lista de Personales Vinculados</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="modalRegPersonalesVinculados=true">
                                            <i class="fas fa-plus fa-sm"></i> Agregar
                                        </CButton>
                                    </b-col>

                                    <b-col md="12">
                                        <b-table :items="datosAccionIncidentes.listPersonalesVinculados" :fields="cabeceraPersonalesVinculados" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                            <template v-slot:cell(nombres)="data">
                                                {{data.item.nombre}}
                                            </template>
                                            <template v-slot:cell(apellido)="data">
                                                {{data.item.apellido}}
                                            </template>
                                            <template v-slot:cell(nrodoc)="data">
                                                {{data.item.nrodoc}}
                                            </template>
                                            <template v-slot:cell(opciones)="param">
                                                <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActPersonalesVinculados(param)" variant="dark" class=" mr-1 mb-1">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                    </span>
                                                </b-button>

                                                <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarPersonalesVinculados(param)" variant="danger">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </span>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>

                                    <b-col md="12">
                                        <validation-provider name="detalle" v-slot="validationContext">
                                            <b-form-group label="Detalle:" class="mb-2">
                                                <b-form-textarea v-model="datosAccionIncidentes.detalle" :state="getValidationState(validationContext)" placeholder="Ingrese el Detalle" rows="3" max-rows="6"></b-form-textarea>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="12" class="text-center my-2">
                                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                            Guardar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </CCardBody>
                </CCard>
            </b-col>
        </b-row>
        <!-- PASAJEROS VINCULADOS -->
        <CModal :show.sync="modalRegPasajeroVinculados" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Agregar Pasajeros Vinculados </span>
                </h6>
                <CButtonClose @click="modalRegPasajeroVinculados = false" class="text-white" />
            </template>
            <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(agregarPasajerosVinculados)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosRegPasajerosVinculados.nombre"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="apellido" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Apellido" v-model.trim="datosRegPasajerosVinculados.apellido"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosRegPasajerosVinculados.nrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegPasajeroVinculados = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActPasajeroVinculados" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Actualizar Pasajeros Vinculados </span>
                </h6>
                <CButtonClose @click="modalActPasajeroVinculados = false" class="text-white" />
            </template>
            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarPasajerosVinculados)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosActPasajerosVinculados.nombre"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="apellido" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Apellido" v-model.trim="datosActPasajerosVinculados.apellido"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosActPasajerosVinculados.nrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button variant="danger" @click="modalActPasajeroVinculados = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <!-- PERSONALES VINCULADOS -->
        <CModal :show.sync="modalRegPersonalesVinculados" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Agregar Personales Vinculados </span>
                </h6>
                <CButtonClose @click="modalRegPersonalesVinculados = false" class="text-white" />
            </template>
            <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(agregarPersonalesVinculados)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosRegPersonalesVinculados.nombre"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="apellido" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Apellido" v-model.trim="datosRegPersonalesVinculados.apellido"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosRegPersonalesVinculados.nrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegPersonalesVinculados = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActPersonalesVinculados" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Actualizar Personales Vinculados </span>
                </h6>
                <CButtonClose @click="modalActPersonalesVinculados = false" class="text-white" />
            </template>
            <validation-observer ref="observer4" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarPersonalesVinculados)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosActPersonalesVinculados.nombre"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="apellido" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Apellido" v-model.trim="datosActPersonalesVinculados.apellido"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosActPersonalesVinculados.nrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button variant="danger" @click="modalActPersonalesVinculados = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbAccidenteIncidentes = firebase.firestore().collection('accidenteIncidentes');

export default {
    data() {
        return {
            disabled: false,
            listUsuarios: [],

            datosAccionIncidentes: {
                idGuia: '',
                fecha: moment().format('YYYY-MM-DD'),
                diaViaje: '',
                listPasajerosVinculados: [],
                listPersonalesVinculados: [],
                detalle: '',
            },

            datosRegPasajerosVinculados: {
                nombre: '',
                apellido: '',
                nrodoc: '',
            },

            datosActPasajerosVinculados: {
                id: '',
                nombre: '',
                apellido: '',
                nrodoc: '',
            },

            datosRegPersonalesVinculados: {
                nombre: '',
                apellido: '',
                nrodoc: '',
            },

            datosActPersonalesVinculados: {
                id: '',
                nombre: '',
                apellido: '',
                nrodoc: '',
            },

            cabeceraPasajeroVinculados: [
                {
                    key: 'nombres',
                    label: 'Nombres',
                    class: 'text-center',
                },
                {
                    key: 'apellido',
                    label: 'Apellido',
                    class: 'text-center',
                },
                {
                    key: 'nrodoc',
                    label: 'Nro. Documento',
                    class: 'text-center',
                },
                {
                    key: 'opciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            cabeceraPersonalesVinculados: [
                {
                    key: 'nombres',
                    label: 'Nombres',
                    class: 'text-center',
                },
                {
                    key: 'apellido',
                    label: 'Apellido',
                    class: 'text-center',
                },
                {
                    key: 'nrodoc',
                    label: 'Nro. Documento',
                    class: 'text-center',
                },
                {
                    key: 'opciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            modalRegPasajeroVinculados: false,
            modalActPasajeroVinculados: false,
            modalRegPersonalesVinculados: false,
            modalActPersonalesVinculados: false,
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurAccidenteIncidentes() {
            this.computedForms.refs['guia principal'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });

        },
        //////////// PASAJEROS VINCULADOS  ////////////
        eliminarPasajeroVinculados(param) {
            let me = this;

            me.datosAccionIncidentes.listPasajerosVinculados.splice(param.index , 1);
        },
        abrirModalActPasajerosVinculados(param){
            let me = this;
            me.datosActPasajerosVinculados.id = param.index;
            me.datosActPasajerosVinculados.nombre = param.item.nombre.trim();
            me.datosActPasajerosVinculados.apellido = param.item.apellido.trim();
            me.datosActPasajerosVinculados.nrodoc = param.item.nrodoc.trim();
            me.modalActPasajeroVinculados = true;
        },
        actualizarPasajerosVinculados() {
            let me = this;
            me.disabled = true;

            me.datosAccionIncidentes.listPasajerosVinculados[me.datosActPasajerosVinculados.id]['nombre'] = me.datosActPasajerosVinculados.nombre;
            me.datosAccionIncidentes.listPasajerosVinculados[me.datosActPasajerosVinculados.id]['apellido'] = me.datosActPasajerosVinculados.apellido;
            me.datosAccionIncidentes.listPasajerosVinculados[me.datosActPasajerosVinculados.id]['nrodoc'] = me.datosActPasajerosVinculados.nrodoc;

            me.modalActPasajeroVinculados = false;
            me.disabled = false;
        },
        agregarPasajerosVinculados() {
            let me = this;

            me.disabled = true;
            // console.log(me.$uuid.v4());

            me.datosAccionIncidentes.listPasajerosVinculados.push({
                nombre: me.datosRegPasajerosVinculados.nombre.trim(),
                apellido: me.datosRegPasajerosVinculados.apellido.trim(),
                nrodoc: me.datosRegPasajerosVinculados.nrodoc.trim(),
            });

            me.modalRegPasajeroVinculados = false;
            me.disabled = false;
        },

        //////// PERSONALES VINCULADOS //////
        agregarPersonalesVinculados() {
            let me = this;

            me.disabled = true;

            me.datosAccionIncidentes.listPersonalesVinculados.push({
                nombre: me.datosRegPersonalesVinculados.nombre.trim(),
                apellido: me.datosRegPersonalesVinculados.apellido.trim(),
                nrodoc: me.datosRegPersonalesVinculados.nrodoc.trim(),
            });

            me.modalRegPersonalesVinculados = false;
            me.disabled = false;

        },
        eliminarPersonalesVinculados(param) {
            let me = this;

            me.datosAccionIncidentes.listPersonalesVinculados.splice(param.index , 1);
        },
        abrirModalActPersonalesVinculados(param){
            let me = this;
            me.datosActPersonalesVinculados.id = param.index;
            me.datosActPersonalesVinculados.nombre = param.item.nombre.trim();
            me.datosActPersonalesVinculados.apellido = param.item.apellido.trim();
            me.datosActPersonalesVinculados.nrodoc = param.item.nrodoc.trim();
            me.modalActPersonalesVinculados = true;
        },
        actualizarPersonalesVinculados() {
            let me = this;
            me.disabled = true;

            me.datosAccionIncidentes.listPersonalesVinculados[me.datosActPersonalesVinculados.id]['nombre'] = me.datosActPersonalesVinculados.nombre;
            me.datosAccionIncidentes.listPersonalesVinculados[me.datosActPersonalesVinculados.id]['apellido'] = me.datosActPersonalesVinculados.apellido;
            me.datosAccionIncidentes.listPersonalesVinculados[me.datosActPersonalesVinculados.id]['nrodoc'] = me.datosActPersonalesVinculados.nrodoc;

            me.modalActPersonalesVinculados = false;
            me.disabled = false;
        },
        //////////////////////////////////////
        varificarAccion() {
            if (this.$route.params.id == null) {
                this.registrarAccidenteIncidente();
            } else if (this.$route.params.id != null) {
                this.actualizarAccidenteIncidente();
            }
        },
        registrarAccidenteIncidente() {
            let me = this;
            me.disabled = true;
            
            if(me.datosAccionIncidentes.listPasajerosVinculados.length <= 0 && me.datosAccionIncidentes.listPersonalesVinculados.length <= 0){
                me.swat('info', 'Llene un Pasajero Vinculado o un Personal Vinculado');
                me.disabled = false;
            }else{
                dbAccidenteIncidentes.add({
                    idGuia: me.datosAccionIncidentes.idGuia,
                    fecha: me.datosAccionIncidentes.fecha,
                    diaViaje: me.datosAccionIncidentes.diaViaje.trim(),
                    listPasajerosVinculados: me.datosAccionIncidentes.listPasajerosVinculados,
                    listPersonalesVinculados: me.datosAccionIncidentes.listPersonalesVinculados,
                    detalle: me.datosAccionIncidentes.detalle.trim(),
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaEliminar: '',
                    fechaModificacion: '',
                    estado: 2,
                    idCliente: me.$store.state.user.idCliente.trim(),
                }).then((check) =>{
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.$router.push({
                        name: 'Accidente e Incidentes'
                    });
                    me.disabled = false;
                }).catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!');
                });
            }
           
        },
        actualizarAccidenteIncidente() {
            let me = this;
            me.disabled = true;

            if(me.datosAccionIncidentes.listPasajerosVinculados.length <= 0 && me.datosAccionIncidentes.listPersonalesVinculados.length <= 0){
                me.swat('info', 'Llene un Pasajeros Vinculados o los Personales Vinculados');
                me.disabled = false;
            }else{
                dbAccidenteIncidentes.doc(me.$route.params.id).update({
                    idGuia: me.datosAccionIncidentes.idGuia,
                    fecha: me.datosAccionIncidentes.fecha,
                    diaViaje: me.datosAccionIncidentes.diaViaje.trim(),
                    listPasajerosVinculados: me.datosAccionIncidentes.listPasajerosVinculados,
	                listPersonalesVinculados: me.datosAccionIncidentes.listPersonalesVinculados,
                    detalle: me.datosAccionIncidentes.detalle.trim(),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                }).then(() => {
                    me.swat('success', 'Modificado satisfactoriamente');
                    me.$router.push({
                        name: 'Accidente e Incidentes'
                    });
                    me.disabled = false;
                }).catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
            }
            
        },
        obtenerAccionIncidente() {
            let me = this;
            me.disabled = true;
            dbAccidenteIncidentes.doc(me.$route.params.id).get()
            .then((doc) =>{
                me.datosAccionIncidentes.idGuia = doc.data().idGuia;
                me.datosAccionIncidentes.fecha = doc.data().fecha;
                me.datosAccionIncidentes.diaViaje = doc.data().diaViaje;
                me.datosAccionIncidentes.listPasajerosVinculados = doc.data().listPasajerosVinculados;
                me.datosAccionIncidentes.listPersonalesVinculados = doc.data().listPersonalesVinculados;
                me.datosAccionIncidentes.detalle = doc.data().detalle;
                me.disabled = false;
            }).catch((error)=>{
                me.swat('error', 'Algo salió mal!');
                me.disabled = false;
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegPasajeroVinculados() {
            this.$nextTick(() => {
                this.$refs.observer1.reset();
            });

            this.datosRegPasajerosVinculados.nombre = '';
            this.datosRegPasajerosVinculados.apellido = '';
            this.datosRegPasajerosVinculados.nrodoc = '';
        },

        resetModalActPasajeroVinculados() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

            this.datosActPasajerosVinculados.id = '';
            this.datosActPasajerosVinculados.nombre = '';
            this.datosActPasajerosVinculados.apellido = '';
            this.datosActPasajerosVinculados.nrodoc = '';
        },

        resetModalRegPersonalesVinculados() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });

            this.datosRegPersonalesVinculados.nombre = '';
            this.datosRegPersonalesVinculados.apellido = '';
            this.datosRegPersonalesVinculados.nrodoc = '';
        },

        resetModalActPersonalesVinculados() {
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });

            this.datosActPersonalesVinculados.id = '';
            this.datosActPersonalesVinculados.nombre = '';
            this.datosActPersonalesVinculados.apellido = '';
            this.datosActPersonalesVinculados.nrodoc = '';
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
    },
    watch: {
        modalRegPasajeroVinculados: function (val){
            if(val == false){
                this.resetModalRegPasajeroVinculados();
            }
        },
        modalActPasajeroVinculados: function (val){
            if(val == false){
                this.resetModalActPasajeroVinculados();
            }
        },
        modalRegPersonalesVinculados: function (val){
            if(val == false){
                this.resetModalRegPersonalesVinculados();
            }
        },

        modalActPersonalesVinculados: function (val){
            if(val == false){
                this.resetModalActPersonalesVinculados();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            if(this.$route.params.id != null){
                this.obtenerAccionIncidente();
            }
        }
    }
}
</script>